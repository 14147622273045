import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Store } from '@ngxs/store';
import { QUERY_PARAMS_MAP } from '@core/utils/routing/query-params.map';
import { AuthState } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private store: Store,
    private router: Router,
  ) {}

  canActivate(_, { url }: RouterStateSnapshot): boolean {
    const isSignedIn = this.isSignedIn();

    if (isSignedIn) {
      return true;
    }

    this.redirectToAuth(url);
    return false;
  }

  isSignedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.store.selectSnapshot(AuthState.isSignedIn);
    }

    return isPlatformServer(this.platformId);
  }

  redirectToAuth(redirectTo: string): void {
    this.router.navigate(['/login'], {
      queryParams: {
        [QUERY_PARAMS_MAP.redirectTo]: redirectTo,
      },
    });
  }
}
