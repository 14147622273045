export interface ClosSubscription {
  planId: string;
  expiresAt: string;
}

export interface ClosSubscriptionPlan {
  id: string;
  name: string;
  /** in USD cents */
  price: number;
  period: SubscriptionDurationPeriod;
}

export enum SubscriptionDurationPeriod {
  month = 'month',
  year = 'year',
}
