import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanLoad } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const hasEntered = Symbol('hasEntered');
const PWD_LS_KEY = 'pwd';

@Injectable({
  providedIn: 'root',
})
export class OnlyTestUsers implements CanLoad {
  [hasEntered]: boolean;

  constructor(
    @Inject(PLATFORM_ID) private pId: string,
    private http: HttpClient,
  ) {}

  canLoad(): Observable<boolean> | boolean {
    if (!isPlatformBrowser(this.pId)) {
      return true;
    }

    if (
      this[hasEntered] ||
      environment.production ||
      document.location.hostname === 'localhost' ||
      document.location.hostname === '192.168.114.157'
    ) {
      return true;
    }

    const pwd = localStorage.getItem(PWD_LS_KEY) || prompt('enter key:');

    return this.http.post('/pwd/', { pwd }).pipe(
      tap(() => {
        localStorage.setItem(PWD_LS_KEY, pwd);
        this[hasEntered] = true;
      }),
      catchError(() => {
        localStorage.removeItem(PWD_LS_KEY);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.canLoad() as Observable<any>;
      }),
    );
  }
}
