import { Compiler, Injectable, Injector } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { LazyPart } from '../lazy/types';

@Injectable()
export class PopupLoader {
  private loader$: Observable<LazyPart>;

  constructor(
    private injector: Injector,
    private compiler: Compiler,
  ) {}

  getLazyPart$(): Observable<LazyPart> {
    if (this.loader$) {
      return this.loader$;
    }

    return (this.loader$ = from(
      import(/* webpackChunkName: "popup-lazy-module" */ '../lazy/popup-lazy.module'),
    ).pipe(
      map(({ PopupLazyModule }) => PopupLazyModule),
      switchMap((module) =>
        from(this.compiler.compileModuleAsync(module)).pipe(
          map((factory) => factory.create(this.injector).instance.embedder),
          map((embedder) => {
            const {
              AlertComponent,
              ConfirmComponent,
              ViewPhotoComponent,
              UploadStatusComponent,
              InformMessageComponent,
            } = module.exports;
            const renderer = embedder.attach(document.body);
            return {
              renderer,
              alert: AlertComponent,
              confirm: ConfirmComponent,
              viewPhoto: ViewPhotoComponent,
              informMessage: InformMessageComponent,
              uploadStatus: UploadStatusComponent,
            };
          }),
        ),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ));
  }
}
