import { TrackableAction } from '../processing';
import { ClosSubscriptionPlan } from './types';

export class FetchSubscriptionsPlans implements TrackableAction {
  readonly track = true;

  static readonly type = '[Subscriptions] Fetch Subscriptions Plans';
}

export class OpenCheckoutPage implements TrackableAction {
  readonly track = true;

  constructor(readonly payload: { plan: ClosSubscriptionPlan }) {}

  static readonly type = '[Subscriptions] Open Checkout Page';
}

export class FetchSubscriptions implements TrackableAction {
  readonly track = true;

  static readonly type = '[Subscriptions] Fetch Subscriptions';
}

export class OpenSubscriptionsAccount implements TrackableAction {
  readonly track = true;

  static readonly type = '[Subscriptions] Open Subscriptions Account';
}

export class SubscriptionPurchaseConfirmation implements TrackableAction {
  readonly track = true;

  static readonly type = '[Subscriptions] Subscription Purchase Confirmation';
}
