import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { Photo } from '../../routes/gallery/routes/album/models/album.model';

export enum DefaultPopupTheme {
  primary = 'btn-g_white-transparent',
  error = 'btn-g_red',
  custom = 'custom-btn',
  blackError = 'btn-g_black-red',
}

export interface AlertRequest {
  title: string;
  text: string;
  theme: DefaultPopupTheme;
  okBtn: string;
}

export interface InformRequest {
  title: string;
}

export interface ViewPhotoRequest {
  photos: Photo[];
  selectedPhoto: Photo;
  roomId: string;
  checkHiRes: boolean;
  canLike: boolean;
}

export interface ConfirmRequest extends AlertRequest {
  cancelBtn: string;
  cancelBtnTheme?: DefaultPopupTheme;
}

export interface AsPopup<T> {
  // eslint-disable-next-line rxjs/finnish
  result: Observable<T | void>;

  onAction(): void;

  focus(): void;
}

export type AsPopupInstance<T> = Type<AsPopup<T>>;

export interface OpenRequest<R, D extends Partial<AsPopupInstance<R>> = object> {
  id?: string;
  inputs?: D;
  wrapped?: boolean;
  type: AsPopupInstance<R>;
}

export type AlertAnswer = true | void;

export type ConfirmAnswer = boolean | void;

export type ViewPhoto = Photo[] | void;

export type UploadStatus = void;

export type InformMessage = void;
