import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
})
export class RoomsApiModule {
  static forRoot(providers: Provider[]): ModuleWithProviders<RoomsApiModule> {
    return {
      ngModule: RoomsApiModule,
      providers,
    };
  }
}
