import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { first, Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  get isNewVersionAvailable$(): Observable<boolean> {
    return this.isNewVersionAvailable$$;
  }

  private isNewVersionAvailable$$ = new ReplaySubject<boolean>(1);

  constructor(
    @Inject(IS_BROWSER) private isBrowser: boolean,
    @Inject(DOCUMENT) private document: Document,
    private updates: SwUpdate,
    private appRef: ApplicationRef,
  ) {}

  init(): void {
    if (!this.isBrowser || !this.updates.isEnabled) {
      return;
    }

    this.updates.versionUpdates
      .pipe(
        filter(({ type }) => type === 'VERSION_READY'),
        first(),
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        this.isNewVersionAvailable$$.next(true);
        this.isNewVersionAvailable$$.complete();
      });

    // eslint-disable-next-line rxjs-angular/prefer-takeuntil
    this.appRef.isStable.pipe(first()).subscribe(() => this.updates.checkForUpdate());
  }

  updateApp(): void {
    if (this.isBrowser) {
      this.document.defaultView?.location.reload();
    }
  }
}
