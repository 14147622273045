import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from './types.dto';
import { User } from './types';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  constructor(private http: HttpClient) {}

  login(token: string): Observable<User> {
    return this.http
      .post<UserDTO>(
        `${environment.api.baseUrl}/auth/login`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .pipe(
        map(({ name, _id: id }) => ({
          id,
          name,
        })),
      );
  }
}
