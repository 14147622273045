import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProviderType, RefreshTokenOutput, SignInInput, SignInOutput } from './types';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  protected fireAuth = inject(AngularFireAuth);

  signIn({ providerType }: SignInInput): Observable<SignInOutput> {
    const provider = this.getProviderByType(providerType);

    return from(this.fireAuth.signInWithPopup(provider)).pipe(
      switchMap(({ user }) => {
        if (!user) {
          throw new Error(`[Authorization has been aborted]`);
        }

        return from(user.getIdToken(false)).pipe(
          map((token) => ({
            token,
            providerProfiles: user.providerData?.filter(Boolean) || [],
          })),
        );
      }),
    );
  }

  refreshToken(): Observable<RefreshTokenOutput> {
    return this.fireAuth.authState.pipe(
      switchMap((user) => {
        if (!user) {
          throw new Error(`[Firebase user is invalid, the token can't be retrieved]`);
        }

        return user.getIdToken(true);
      }),
      map((token) => ({ token })),
      take(1),
    );
  }

  signOut(): Observable<unknown> {
    return from(this.fireAuth.signOut());
  }

  protected getProviderByType(type: FirebaseAuthProviderType): firebase.auth.AuthProvider {
    const providersByTypes = {
      [FirebaseAuthProviderType.google]: new firebase.auth.GoogleAuthProvider(),
      [FirebaseAuthProviderType.apple]: new firebase.auth.OAuthProvider('apple.com'),
    } as const;
    const provider = providersByTypes[type];

    if (!provider) {
      throw new Error(`[Provider of ${type} is not supported]`);
    }

    return provider;
  }
}
