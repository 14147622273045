import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { PatchUserPreferences } from './user-preferences.actions';
import { UserPreferences } from './types';

export type UserPreferencesStateModel = UserPreferences;

@State<UserPreferencesStateModel>({
  name: 'userPreferences',
  defaults: {
    showOnlyMyArchivesByDefault: true,
  },
})
@Injectable()
export class UserPreferencesState {
  static showOnlyMyArchives = createSelector(
    [UserPreferencesState],
    (state: UserPreferencesStateModel) => state.showOnlyMyArchivesByDefault,
  );

  @Action(PatchUserPreferences)
  onPatchPreferences(
    ctx: StateContext<UserPreferencesStateModel>,
    { payload }: PatchUserPreferences,
  ) {
    ctx.patchState(payload);
  }
}
