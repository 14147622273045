import { SignInProvider, User } from './auth.models';
import { TrackableAction } from '../../../state/processing';

export class SignIn implements TrackableAction {
  static readonly type = '[Auth] Sign In';

  readonly track = true;

  constructor(public payload: { provider: SignInProvider }) {}
}

export class SignOut implements TrackableAction {
  static readonly type = '[Auth] Sign Out';

  readonly track = true;
}

export class PatchUser {
  static readonly type = '[Auth] Patch User';

  constructor(public payload: User) {}
}

export class RefreshToken implements TrackableAction {
  static readonly type = '[Auth] Refresh Token';

  readonly track = true;
}
