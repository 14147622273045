import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LogoutEffect } from './effects/logout-effect';
import { TokenRefreshEffect } from './effects/token-refresh-effect';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LogoutEffect, TokenRefreshEffect],
})
export class AppComponent implements OnInit {
  constructor(
    protected logoutEffect: LogoutEffect,
    protected tokenRefreshEffect: TokenRefreshEffect,
  ) {}

  ngOnInit() {
    this.logoutEffect.use();
    this.tokenRefreshEffect.use();
  }
}
