import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { AppLanguages } from '@core/i18n/langs-available';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';

@Injectable({
  providedIn: 'root',
})
export class ExternalRouteResolver implements Resolve<null> {
  private isBrowser = inject(IS_BROWSER);

  private response = inject(RESPONSE, {
    optional: true,
  });

  constructor(private translate: TranslateService) {}

  resolve(route: ActivatedRouteSnapshot): null {
    const { external } = route.data;
    if (!external) {
      throw new Error(`[no external (url|source) was provided]`);
    }

    if (typeof external === 'string') {
      this.redirectToExternal(external);
    } else if (typeof external === 'object') {
      const extracted = external[this.translate.currentLang || AppLanguages.en];

      if (!extracted) {
        throw new Error(`[url was not provided for current language]`);
      }

      this.redirectToExternal(extracted);
    } else {
      throw new Error('[unsupported data for external redirect: need string or object]');
    }

    return null;
  }

  private redirectToExternal(url: string): void {
    if (this.isBrowser) {
      return window.location.replace(url);
    }

    this.response?.redirect(url);
  }
}
