import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthState } from '@core/auth/state/auth.state';
import { RefreshToken } from '@core/auth/state/auth.actions';
import { PopupService } from '@shared/popup/services/popup.service';
import { DefaultPopupTheme } from '@shared/popup/models';
import { ProcessingState } from '../../state/processing';

@Injectable({
  providedIn: 'root',
})
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private actions$: Actions,
    private readonly popup: PopupService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !req.url.startsWith(environment.api.baseUrl) &&
      !req.url.startsWith(environment.api.storeBaseUrl)
    ) {
      return next.handle(req);
    }

    return next.handle(this.cloneWithCoreHeaders(req)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (this.isErrorWithTokenExpired(err.error)) {
          return this.handleErrorWithTokenExpired(req, next);
        }

        if (err.status === 426) {
          return this.popup
            .alert({
              title: 'The version of the application is outdated, please restart the page',
              okBtn: 'RESTART',
              theme: DefaultPopupTheme.error,
              text: '',
            })
            .pipe(
              tap(() => location.reload()),
              switchMap(() => next.handle(this.cloneWithCoreHeaders(req))),
            );
        }

        return throwError(err.error);
      }),
    );
  }

  protected handleErrorWithTokenExpired(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isTokenBeingRefreshed = this.store.selectSnapshot(
      ProcessingState.hasBeingPreparedOf([RefreshToken]),
    );

    if (!isTokenBeingRefreshed) {
      this.store.dispatch(new RefreshToken());
    }

    return this.actions$.pipe(
      ofActionCompleted(RefreshToken),
      tap(({ result: { successful } }) => {
        if (!successful) {
          throw new Error(`[Token refresh has failed]`);
        }
      }),
      switchMap(() => next.handle(this.cloneWithCoreHeaders(req))),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected isErrorWithTokenExpired(err: any): boolean {
    return err?.code === 101 && err?.description === 'not valid firebase token';
  }

  protected cloneWithCoreHeaders<T>(req: HttpRequest<T>): HttpRequest<T> {
    let headers = this.setAuthorization(req.headers);
    headers = this.setApiVersion(headers);
    headers = this.setApiName(headers);
    headers = this.setPlatform(headers);

    return req.clone({ headers });
  }

  protected setAuthorization(headers: HttpHeaders): HttpHeaders {
    const tk = this.store.selectSnapshot(AuthState.token);

    if (!tk) {
      return headers;
    }

    return headers.set('Authorization', `Bearer ${tk}`);
  }

  protected setApiVersion(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-version', environment.api.ver);
  }

  protected setPlatform(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-App-Platform', environment.api.platform);
  }

  protected setApiName(headers: HttpHeaders): HttpHeaders {
    return headers.set('X-App-Name', environment.api.appName);
  }
}
