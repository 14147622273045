import { Routes } from '@angular/router';
import { AppLanguages } from './langs-available';
import { I18nRootGuard } from './i18n.root-guard';

export const I18nRoutes = (
  routes: Routes,
  languages: AppLanguages[] = [AppLanguages.en, AppLanguages.ru],
): Routes => [
  ...languages.map((lang) => ({
    path: lang,
    canActivate: [I18nRootGuard],
    children: routes,
  })),
  {
    path: '',
    canActivate: [I18nRootGuard],
    children: routes,
  },
];
