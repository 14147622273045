import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  GetCheckoutUrlResponse,
  GetPaymentAccountUrlResponse,
  GetPlansResponse,
  PlanDTO,
  SubscriptionDTO,
} from './types.dto';
import { ClosSubscriptionPlan, ClosSubscription, SubscriptionDurationPeriod } from './types';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsApi {
  protected baseUrl = `${environment.api.baseUrl}/v1/subscription`;

  constructor(private http: HttpClient) {}

  getSubscriptionsPlans(): Observable<ClosSubscriptionPlan[]> {
    return this.http.get<GetPlansResponse>(`${this.baseUrl}/list`).pipe(
      map(({ prices }) =>
        prices
          .sort((a, b) => a.value - b.value)
          .map(({ id, name, value, interval }) => ({
            price: value,
            name,
            id,
            period: this.toSubscriptionPeriod(interval),
          })),
      ),
    );
  }

  getCheckoutUrl(plan: ClosSubscriptionPlan): Observable<string> {
    return this.http
      .post<GetCheckoutUrlResponse>(`${this.baseUrl}/checkout`, { product_id: plan.id })
      .pipe(map((response) => response.checkout_url));
  }

  getSubscriptions(): Observable<ClosSubscription[]> {
    return this.http.get<SubscriptionDTO | null>(`${this.baseUrl}/info`).pipe(
      map((response) => {
        if (response && response.subscription_id) {
          return [
            {
              planId: response.subscription_id,
              expiresAt: response.expires_at,
            },
          ];
        }

        return [];
      }),
    );
  }

  getPaymentsAccountUrl(): Observable<string> {
    return this.http
      .get<GetPaymentAccountUrlResponse>(`${this.baseUrl}/portal`)
      .pipe(map(({ portal_url: portalUrl }) => portalUrl));
  }

  protected toSubscriptionPeriod(interval: PlanDTO['interval']): SubscriptionDurationPeriod {
    if (interval === 'month') {
      return SubscriptionDurationPeriod.month;
    }

    return SubscriptionDurationPeriod.year;
  }
}
