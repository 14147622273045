import { inject, Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { NEVER, retry } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ClosSubscription, ClosSubscriptionPlan } from './types';
import {
  FetchSubscriptions,
  FetchSubscriptionsPlans,
  OpenCheckoutPage,
  OpenSubscriptionsAccount,
  SubscriptionPurchaseConfirmation,
} from './actions';
import { SubscriptionsApi } from './subscriptions.api';

export interface SubscriptionsStateModel {
  subscriptions: ClosSubscription[];
  subscriptionsPlans: ClosSubscriptionPlan[];
}

@State<SubscriptionsStateModel>({
  name: 'subscriptions',
  defaults: {
    subscriptions: [],
    subscriptionsPlans: [],
  },
})
@Injectable()
export class SubscriptionsState {
  static plans = createSelector(
    [SubscriptionsState],
    (state: SubscriptionsStateModel) => state.subscriptionsPlans,
  );

  static subscriptions = createSelector(
    [SubscriptionsState],
    (state: SubscriptionsStateModel) => state.subscriptions,
  );

  static hasSubscription = createSelector(
    [SubscriptionsState.subscriptions],
    (subscriptions: ClosSubscription[]) => subscriptions.length > 0,
  );

  static activeSubscriptions = createSelector(
    [SubscriptionsState.subscriptions],
    (subscriptions: ClosSubscription[]) =>
      subscriptions.filter(({ expiresAt }) => new Date(expiresAt).getTime() > Date.now()),
  );

  static hasActiveSubscription = createSelector(
    [SubscriptionsState.activeSubscriptions],
    (subscriptions: ClosSubscription[]) => subscriptions.length > 0,
  );

  static hasPlans = createSelector(
    [SubscriptionsState.plans],
    (plans: ClosSubscriptionPlan[]) => plans.length > 0,
  );

  static planById = (planId: string) =>
    createSelector([SubscriptionsState.plans], (subscriptionsPlans: ClosSubscriptionPlan[]) =>
      subscriptionsPlans.find((plan) => plan.id === planId),
    );

  protected api = inject(SubscriptionsApi);

  @Action(FetchSubscriptionsPlans, { cancelUncompleted: true })
  protected onFetchSubscriptionsPlans(ctx: StateContext<SubscriptionsStateModel>) {
    return this.api
      .getSubscriptionsPlans()
      .pipe(tap((subscriptionsPlans) => ctx.patchState({ subscriptionsPlans })));
  }

  @Action(OpenCheckoutPage, { cancelUncompleted: true })
  protected onOpenCheckoutPage(
    _: StateContext<SubscriptionsStateModel>,
    { payload }: OpenCheckoutPage,
  ) {
    return this.api.getCheckoutUrl(payload.plan).pipe(
      tap((checkoutUrl) => {
        window.location.href = checkoutUrl;
      }),
      switchMap(() => NEVER),
    );
  }

  @Action(FetchSubscriptions)
  protected onFetchSubscriptions(ctx: StateContext<SubscriptionsStateModel>) {
    return this.api
      .getSubscriptions()
      .pipe(tap((subscriptions) => ctx.patchState({ subscriptions })));
  }

  @Action(OpenSubscriptionsAccount, { cancelUncompleted: true })
  protected onOpenSubscriptionsAccount() {
    return this.api.getPaymentsAccountUrl().pipe(
      tap((subscriptionsAccountUrl) => {
        window.location.href = subscriptionsAccountUrl;
      }),
      switchMap(() => NEVER),
    );
  }

  @Action(SubscriptionPurchaseConfirmation)
  protected onSubscriptionPurchaseConfirmation(ctx: StateContext<SubscriptionsStateModel>) {
    return this.api.getSubscriptions().pipe(
      tap((subscriptions) => {
        const activeSubscriptions = subscriptions.filter(
          ({ expiresAt }) => new Date(expiresAt).getTime() > Date.now(),
        );

        if (!activeSubscriptions.length) {
          throw new Error(`[No active subscriptions found]`);
        }

        ctx.patchState({ subscriptions });
      }),
      retry({ delay: 3000, count: 10 }),
    );
  }
}
