import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { RoomsApiModule } from '../rooms-api.module';

export enum Roles {
  observer = 'observer',
  model = 'model',
  photographer = 'photograph',
}

export type RoomApiModelType = 'standard' | 'share_file';

export type RoomApiModelStatus = 'created'; // for current moment only 'created' status is available

export interface CreateRoomDTO {
  isSaveToCloud: boolean;
  name?: string;
  role?: Roles;
  type?: RoomApiModelType;
}

export interface JoinRoomDTO {
  roomId: string;
  role?: Roles;
}

export interface GetByIdRoomDTO {
  roomId: string;
}

export interface RoomApiModel {
  _id: string;
  owner_id: string;
  owner_name: string;
  photograph_id: string;
  model_id: string;
  participants: string[];
  status: RoomApiModelStatus;
  token: string;
  link: string;
  deeplink: string;
  type: RoomApiModelType;
  is_cloud_images: boolean;
  dropbox_images_count: number;
  local_images_count: number;
  name: string;
}

@Injectable({
  providedIn: RoomsApiModule,
})
export class RoomsApiService {
  private baseUrl = `${environment.api.baseUrl}/v1/room`;

  constructor(private http: HttpClient) {}

  create({ role, isSaveToCloud, type, name }: CreateRoomDTO): Observable<RoomApiModel> {
    return this.http
      .post<{ data: RoomApiModel }>(`${this.baseUrl}`, {
        role,
        is_cloud_images: isSaveToCloud,
        type,
        name,
      })
      .pipe(map(({ data }) => data));
  }

  join({ roomId, role }: JoinRoomDTO): Observable<RoomApiModel> {
    return this.http
      .post<{ data: RoomApiModel }>(`${this.baseUrl}/${roomId}/join`, { role })
      .pipe(map(({ data }) => data));
  }

  getById({ roomId }: GetByIdRoomDTO): Observable<Omit<RoomApiModel, 'token'>> {
    return this.http
      .get<{ data: RoomApiModel }>(`${this.baseUrl}/${roomId}`)
      .pipe(map(({ data }) => data));
  }
}
