export enum FirebaseAuthProviderType {
  google,
  apple,
}

export interface SignInInput {
  providerType: FirebaseAuthProviderType;
}

export interface SignInOutput {
  token: string;
  /**
   * If the array is empty, it means that selected provider doesn't give any user data.
   * This case is not an error scenario.
   */
  providerProfiles: Partial<FirebaseProviderProfile>[];
}

export interface RefreshTokenOutput {
  token: string;
}

/**
 * Data coming from auth provider such as google or apple.
 *
 * It's not a stable structure, significantly depends on the provider API
 * and may vary on the specific provider solutions. Some of the options may not exist at all.
 *
 * Use it with caution to pre-fill some of the app user fields
 */
export interface FirebaseProviderProfile {
  displayName: string;
  email: string;
  photoURL: string;
}
