import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from './services/popup.service';
import { PopupLoader } from './services/popup-loader';

@NgModule({
  imports: [CommonModule],
})
export class PopupModule {
  static forRoot(): ModuleWithProviders<PopupModule> {
    return {
      ngModule: PopupModule,
      providers: [PopupService, PopupLoader],
    };
  }
}
