import { inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { IS_BROWSER } from '@core/browser/tokens/is-browser';
import { Store } from '@ngxs/store';
import { FetchSubscriptions } from '../../state/subscriptions';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsStateInitGuard implements CanActivate {
  protected isBrowser = inject(IS_BROWSER);

  protected store = inject(Store);

  canActivate(): boolean {
    if (this.isBrowser) {
      this.store.dispatch(new FetchSubscriptions());
    }

    return true;
  }
}
