import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import 'focus-visible';

if (environment.production) {
  enableProdMode();
  // tslint:disable-next-line:no-console
  console.log(`[APP_VERSION]: ${environment.version}`);
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
