import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { SignOut } from '@core/auth/state';

@Injectable()
export class LogoutEffect implements OnDestroy {
  protected destroy$ = new Subject<void>();

  constructor(
    protected actions$: Actions,
    protected router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  use(): void {
    this.actions$
      .pipe(
        ofActionSuccessful(SignOut),
        tap(() => this.router.navigate(['/login'])),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
