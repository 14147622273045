import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { switchMap, tap } from 'rxjs/operators';
import { FirebaseAuthService } from './firebase-auth';
import { AuthApi } from './auth-api';
import { PatchUser, RefreshToken, SignIn, SignOut } from './auth.actions';
import { User, ProviderProfile } from './auth.models';

const AUTH_STATE_NAME = 'auth';

export class AuthStateModel {
  activeUsers: User[];

  tokens: string[];

  authProvidersAdditionalData: Partial<ProviderProfile>[];
}

@State<AuthStateModel>({
  name: AUTH_STATE_NAME,
  defaults: {
    activeUsers: [],
    tokens: [],
    authProvidersAdditionalData: [],
  },
})
@Injectable()
export class AuthState {
  static readonly stateName = AUTH_STATE_NAME;

  static token = createSelector([AuthState], ({ tokens: [token] }: AuthStateModel) => token);

  static user = createSelector([AuthState], ({ activeUsers: [user] }: AuthStateModel) => user);

  static isSignedIn = createSelector(
    [AuthState.user, AuthState.token],
    (user: User, token: string) => !!(token && user),
  );

  constructor(
    protected firebaseAuthService: FirebaseAuthService,
    protected authApi: AuthApi,
  ) {}

  @Action(PatchUser)
  patchUser({ setState }: StateContext<AuthStateModel>, { payload }: PatchUser): void {
    setState(
      patch({
        activeUsers: updateItem<User>((user) => user.id === payload.id, payload),
      }),
    );
  }

  @Action(SignIn)
  protected onSignIn({ patchState }: StateContext<AuthStateModel>, { payload }: SignIn) {
    return this.firebaseAuthService.signIn({ providerType: payload.provider }).pipe(
      tap(({ token, providerProfiles }) => {
        patchState({ tokens: [token], authProvidersAdditionalData: providerProfiles });
      }),
      switchMap(({ token }) => this.authApi.login(token)),
      tap((user) => patchState({ activeUsers: [user] })),
    );
  }

  @Action(SignOut)
  protected onSignOut({ setState }: StateContext<AuthStateModel>) {
    return this.firebaseAuthService.signOut().pipe(
      tap(() =>
        setState({
          authProvidersAdditionalData: [],
          tokens: [],
          activeUsers: [],
        }),
      ),
    );
  }

  @Action(RefreshToken)
  protected onRefreshToken({ patchState }: StateContext<AuthStateModel>) {
    return this.firebaseAuthService.refreshToken().pipe(
      tap(({ token }) =>
        patchState({
          tokens: [token],
        }),
      ),
    );
  }
}
