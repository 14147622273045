import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { RefreshToken, SignOut } from '@core/auth/state';
import { ProcessingState } from '../../state/processing';

@Injectable()
export class TokenRefreshEffect implements OnDestroy {
  protected destroy$ = new Subject<void>();

  constructor(
    protected router: Router,
    protected processingState: ProcessingState,
    protected store: Store,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  use(): void {
    this.store
      .select(ProcessingState.hasCompletedWithErrorOf([RefreshToken]))
      .pipe(
        filter(Boolean),
        tap(() => {
          this.store.dispatch(new SignOut());
          this.processingState.removeActionWithErrorBy((action) => {
            return action instanceof RefreshToken;
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
